import {
  GET_BOARD_PENDING,
  GET_BOARD_SUCCESS,
  GET_BOARD_ERROR,
  INITIALIZE_STATE,
  RESET_STATE
} from '../ReduxActions/types';

var initialState = {
  allBoards: [],
  pending: false,
  success: false,
  error: false
};

export default function (state = null, action) {
  let nextState = {};
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_BOARD_PENDING:
      nextState = {
        ...state,
        pending: true,
        success: false,
        allBoards: []
      };
      break;
    case GET_BOARD_SUCCESS:
      nextState = {
        ...state,
        allBoards: action.payload,
        pending: false,
        success: true
      };
      break;
    case GET_BOARD_ERROR:
      nextState = {
        ...state,
        pending: false,
        success: false,
        error: action.payload
      };
      break;
    default:
      return state;
  }
  return nextState;
}
