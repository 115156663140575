import React, { Component } from 'react';
import './style.css';

export default class ULoading extends Component {
  render() {
    return (
      <div
        className={`Loading__Wrapper ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <div className="Loading__Icon">
          <svg
            width="100px"
            height="100px"
            viewBox="0 0 23 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <defs></defs>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Artboard"
                transform="translate(-152.000000, -136.000000)"
                fill="#242C41"
                fillRule="nonzero"
              >
                <g id="giris" transform="translate(152.000000, 136.000000)">
                  <polygon
                    id="Fill-3"
                    points="18.362338 1.54441558 20.456104 2.72064935 20.456104 7.42363636 19.555714 7.92935065 16.269351 6.08415584 14.86739 6.94119607 14.86739 4.28106876 12.082597 2.72064935 16.268961 0.368571429"
                  ></polygon>
                  <polyline
                    id="Fill-4"
                    points="7.286104 0.834935065 14.075455 4.64922078 14.075455 7.3161039 9.415714 9.93350649 9.415714 14.8948052 7.286104 16.0909091 0.496364 12.277013 0.496364 4.64922078 3.891818 2.74207792 7.286104 0.834935065"
                  ></polyline>
                  <polygon
                    id="Fill-5"
                    points="22.212857 10.4454545 22.212857 17.1225974 16.26974 20.4611688 10.326623 17.1225974 10.326623 10.4454545 16.26974 7.10688312"
                  ></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
