import {
    GET_RECEIVER_PENDING,
    GET_RECEIVER_SUCCESS,
    GET_RECEIVER_ERROR,
    INITIALIZE_STATE,
    RESET_STATE
  } from '../ReduxActions/types';
  
  var initialState = {
    allReceivers: [],
    pending: false,
    success: false,
    error: false
  };
  
  export default function (state = null, action) {
    let nextState = {};
    switch (action.type) {
      case INITIALIZE_STATE:
        nextState = {
          ...state,
          ...initialState
        };
        break;
      case RESET_STATE:
        nextState = null;
        break;
      case GET_RECEIVER_PENDING:
        nextState = {
          ...state,
          pending: true,
          success: false,
          allReceivers: []
        };
        break;
      case GET_RECEIVER_SUCCESS:
        nextState = {
          ...state,
          allReceivers: action.payload,
          pending: false,
          success: true
        };
        break;
      case GET_RECEIVER_ERROR:
        nextState = {
          ...state,
          pending: false,
          success: false,
          error: action.payload
        };
        break;
      default:
        return state;
    }
    return nextState;
  }
  