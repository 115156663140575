import * as signalR from '@microsoft/signalr';
import config from '../config';
import { subscribeToStoreOnSignalR } from '../ReduxActions/storeActions';
class SignalR {
  listenedFunctions = [];
  reconnectActions = [];
  ReConnectionOpen = false;
  signalREnable = config.hasRTM;
  socketUrl = config.socketUrl;

  startConnection(token) {
    if (!this.signalREnable) return;
    this.connection = null;
    this.ReConnectionOpen = true;
    this.initConnection(token);
    this.start();
    this.onclose();
    this.reConnectedBeginListen();
    this.OnReconnected();
  }

  initConnection(deftoken) {
    const token =
      deftoken ||
      (localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user')).access_token
        : null);
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.socketUrl, {
        accessTokenFactory: () => {
          return token;
        }
      })
      .withAutomaticReconnect([0, 1000, 2000, 3000, 5000, 9000, 15000])
      .build();
  }

  async start() {
    try {
      await this.connection.start();
    } catch (err) {
      if (!this.getConnectionState())
        setTimeout(() => {
          if (this.ReConnectionOpen) {
            this.start();
          }
        }, 5000);
      console.log('error handle', err);
    }
  }

  onclose() {
    this.connection.onclose(async res => {
      if (this.ReConnectionOpen) {
        this.start();
        return;
      }
    });
  }

  waitFor(conditionFunction) {
    const poll = (resolve, reject) => {
      if (!this.signalREnable) {
        return reject();
      }
      if (conditionFunction()) resolve();
      else
        setTimeout(_ => {
          poll(resolve);
        }, 400);
    };

    return new Promise(poll);
  }

  on(hubMethod, method) {
    this.listenedFunctions.push({ hubMethod, method });
    if (!this.connection) {
      this.waitFor(_ => this.connection !== undefined).then(_ => {
        this.connection.on(hubMethod, method);
        return 0;
      });
    } else {
      this.connection.on(hubMethod, method);
    }
  }

  reConnectedBeginListen() {
    if (!this.connection) return;
    this.listenedFunctions.map(async listenedFunction => {
      let { hubMethod, method } = listenedFunction;
      await this.off(hubMethod, method).then(() => {
        this.on(hubMethod, method);
      });
    });
  }

  afterReconnectActions(actions) {
    this.reconnectActions = actions;
  }

  async off(hubMethod, method) {
    if (!this.connection) return;

    this.listenedFunctions = this.listenedFunctions.filter(
      listenedFunction => listenedFunction.hubMethod !== hubMethod
    );

    this.connection.off(hubMethod, method);
  }

  onreconnecting(onreconnecting = () => {}) {
    this.connection.onreconnecting(error => {
      onreconnecting();
      console.log('onreconnecting', error);
    });
  }

  OnReconnected() {
    this.connection.onreconnected(msg => {
      this.reconnectActions.map(reconnectAction => {
        reconnectAction.action(reconnectAction.params);
      });
    });
  }

  getConnectionState = () => (this.connection ? this.connection.state : false);

  async Disconnect() {
    if (!this.connection) return;
    this.ReConnectionOpen = false;
    this.connection
      .stop()
      .then(() => {
        delete this.connection;
      })
      .catch(console.log);
  }
}

const signalRConnection = new SignalR();

export function startGlobalConnection(token) {
  signalRConnection.startConnection(token);

  const storeId = JSON.parse(localStorage.getItem('SelectedStores') ?? '{}')
    ?.globalStoreParam?.Id;

  if (storeId) {
    subscribeToStoreOnSignalR(storeId);
  }
}

export default signalRConnection;
