export const INITIALIZE_STATE = 'INITIALIZE_STATE';
export const RESET_STATE = 'RESET_STATE';

//Store Actions.
export const GET_STORE_PENDING = 'GET_STORE_PENDING';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const SELECTED_STORE_REFRESH = 'SELECTED_STORE_REFRESH';
export const SELECTED_STORE_CHANGE = 'SELECTED_STORE_CHANGE';
export const STORE_RECTS_PENDING = 'STORE_RECTS_PENDING';
export const STORE_RECTS_SUCCESS = 'STORE_RECTS_SUCCESS';
export const SET_SELECTED_STORE_IDS = 'SET_SELECTED_STORE_IDS';
export const STORE_RECTS_ERROR = 'STORE_RECTS_ERROR';
export const GET_SKETCHS_SUCCESS = 'GET_SKETCHS_SUCCESS';
export const GET_SKETCHS_ERROR = 'GET_SKETCHS_ERROR';
export const SET_COMPARISON_SKETCH_DATA = 'SET_COMPARISON_SKETCH_DATA';
export const GET_PERFORMANCETABLE_PENDING = 'GET_PERFORMANCETABLE_PENDING';
export const GET_PERFORMANCETABLE_SUCCESS = 'GET_PERFORMANCETABLE_SUCCESS';
export const GET_PERFORMANCETABLE_ERROR = 'GET_PERFORMANCETABLE_ERROR';

export const GET_BRAND_PERFORMANCETABLE_PENDING =
  'GET_BRAND_PERFORMANCETABLE_PENDING';
export const GET_BRAND_PERFORMANCETABLE_SUCCESS =
  'GET_BRAND_PERFORMANCETABLE_SUCCESS';
export const GET_BRAND_PERFORMANCETABLE_ERROR =
  'GET_BRAND_PERFORMANCETABLE_ERROR';

export const GET_STORE_SUGGESTION_PENDING = 'GET_STORE_SUGGESTION_PENDING';
export const GET_STORE_SUGGESTION_SUCCESS = 'GET_STORE_SUGGESTION_SUCCESS';
export const GET_STORE_SUGGESTION_ERROR = 'GET_STORE_SUGGESTION_ERROR';
export const PERFORMANCETABLE_DATE_ONCHANGE = 'PERFORMANCETABLE_DATE_ONCHANGE';
export const CLEAR_SELECTED_STORE = 'CLEAR_SELECTED_STORE';
export const GET_WEATHER_DATA = 'GET_WEATHER_DATA';
export const SET_USERS = 'SET_USERS';
export const SET_USERS_STATUS = 'SET_USERS_STATUS';
export const GET_STOREPRODUCTTREE_PENDING = 'GET_STOREPRODUCTTREE_PENDING';
export const GET_STOREPRODUCTTREE_SUCCESS = 'GET_STOREPRODUCTTREE_SUCCESS';
export const GET_STOREPRODUCTTREE_ERROR = 'GET_STOREPRODUCTTREE_ERROR';
export const GET_MAPPEDCATEGORIES_PENDING = 'GET_MAPPEDCATEGORIES_PENDING';
export const GET_MAPPEDCATEGORIES_SUCCESS = 'GET_MAPPEDCATEGORIES_SUCCESS';
export const GET_MAPPEDCATEGORIES_ERROR = 'GET_MAPPEDCATEGORIES_ERROR';
export const SET_STOREALLMAPPEDCATEGORIES = 'SET_STOREALLMAPPEDCATEGORIES';

export const SET_QUERYSTORESELECTOR = 'SET_QUERYSTORESELECTOR';

export const GET_STORE_COMMENT_POPUP_SEEN = 'GET_STORE_COMMENT_POPUP_SEEN';
//Login Actions.
export const REQUEST_LOGIN_PENDING = 'REQUEST_LOGIN_PENDING';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const GET_CHART = 'GET_CHART';

//Params Actions.
export const SET_SHOW_TOTAL = 'SET_SHOW_TOTAL';
export const SET_ALL_STORE_IDS = 'SET_ALL_STORE_IDS';
export const SET_PARAM = 'SET_PARAM';
export const SET_PARAMS = 'SET_PARAMS';
export const SET_MODAL = 'SET_OVERLAY';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_START_TIME = 'SET_START_TIME';
export const SET_PREVSTART_TIME_VALUE = 'SET_PREVSTART_TIME_VALUE';
export const SET_START_TIME_VALUE = 'SET_START_TIME_VALUE';
export const SET_END_TIME = 'SET_END_TIME';
export const SET_END_TIME_VALUE = 'SET_END_TIME_VALUE';
export const SET_PREVEND_TIME_VALUE = 'SET_PREVEND_TIME_VALUE';
export const SET_START_HEATMAP_DATE = 'SET_START_HEATMAP_DATE';
export const SET_END_HEATMAP_DATE = 'SET_END_HEATMAP_DATE';
export const SET_START_HEATMAP_TIME = 'SET_START_HEATMAP_TIME';
export const SET_END_HEATMAP_TIME = 'SET_END_HEATMAP_TIME';
export const SELECTED_COMPARISON_STORE_CHANGE =
  'SELECTED_COMPARISON_STORE_CHANGE';
export const FUNNEL_THRESHOLDS_ADD = 'FUNNEL_THRESHOLDS_ADD';
export const FUNNEL_THRESHOLDS_REMOVE = 'FUNNEL_THRESHOLDS_REMOVE';
export const REMOVE_PARAMS = 'REMOVE_PARAMS';
export const SET_INITIAL = 'SET_INITIAL';
export const SET_SYNC_BOUNDARIES = 'SET_SYNC_BOUNDARIES';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const SET_DATE_PARAMS = 'SET_DATE_PARAMS';
export const SET_COMPARISON_PARAMS = 'SET_COMPARISON_PARAMS';
export const SET_HOMEBRAND_PARAMS = 'SET_HOMEBRAND_PARAMS';
export const SET_PERFORMANCE_TABLE_PARAMS = 'SET_PERFORMANCE_TABLE_PARAMS';
export const SET_COMPARISON_LAYES = 'SET_COMPARISON_LAYES';
export const SET_PREVSTART_DATE = 'SET_PREVSTART_DATE';
export const SET_PREVEND_DATE = 'SET_PREVEND_DATE';
export const SET_PREVSTART_TIME = 'SET_PREVSTART_TIME';
export const SET_PREVEND_TIME = 'SET_PREVEND_TIME';
export const CONVERSION_RATE_THRESOLDS = 'CONVERSION_RATE_THRESOLDS';
export const CONVERSION_RATE_THRESOLDS_REMOVE =
  'CONVERSION_RATE_THRESOLDS_REMOVE';
export const SET_HOMEAGEBRAND_PARAMS = 'SET_HOMEAGEBRAND_PARAMS';

export const SET_METRIC_TYPE = 'SET_METRIC_TYPE';

export const SET_SUGGESTION_DATES = 'SET_SUGGESTION_DATES';

export const SELECT_PERSONNELID_CHANGE = 'SELECT_PERSONNELID_CHANGE';

export const SET_BACKUPDATES = 'SET_BACKUPDATES';

export const GET_WEATHER_DATA_PENDING = 'GET_WEATHER_DATA_PENDING';
export const GET_WEATHER_DATA_SUCCESS = 'GET_WEATHER_DATA_SUCCESS';
export const GET_WEATHER_DATA_ERROR = 'GET_WEATHER_DATA_ERROR';

export const SELECTED_CHECKOUT_COMPARISON_STORE_CHANGE =
  'SELECTED_CHECKOUT_COMPARISON_STORE_CHANGE';
export const SELECTED_AGEGENDER_COMPARISON_STORE_CHANGE =
  'SELECTED_AGEGENDER_COMPARISON_STORE_CHANGE';
export const SET_CATEGORY_FILTER_TYPE = 'SET_CATEGORY_FILTER_TYPE';
export const SET_SELECTED_DEPARTMENTID = 'SET_SELECTED_DEPARTMENTID';
export const SET_SELECTED_PERSONNEL = 'SET_SELECTED_PERSONNEL';
export const SET_IS_PERSONNEL_SELECTED = 'SET_IS_PERSONNEL_SELECTED';
export const WAITING_TIMES_THERESOLDS = 'WAITING_TIMES_THERESOLDS';
export const WAITING_TIMES_THERESOLDS_REMOVE =
  'WAITING_TIMES_THERESOLDS_REMOVE';
export const SET_CORRELATION_THRESHOLD = 'SET_CORRELATION_THRESHOLD';

//User Actions.
export const USER_REQUEST_LOGIN_PENDING = 'USER_REQUEST_LOGIN';
export const USER_REQUEST_LOGIN_SUCCESS = 'USER_REQUEST_LOGIN_SUCCESS';
export const USER_REQUEST_LOGIN_ERROR = 'USER_REQUEST_LOGIN_ERROR';
export const RESET_USER_STATE = 'RESET_USER_STATE';
export const USER_REQUEST_LOGOUT = 'USER_REQUEST_LOGOUT';
export const GET_NOTIFICATIONS_PENDING = 'GET_NOTIFICATIONS_PENDING';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_LAST_FETCHED_NOTIFICATION_COUNT =
  'SET_LAST_FETCHED_NOTIFICATION_COUNT';
export const RESET_NOTIFICATION_COUNT = 'RESET_NOTIFICATION_COUNT';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const GET_PATH = 'GET_PATH';
export const TOGGLE_NOMINAL = 'TOGGLE_NOMINAL';
export const TOGGLE_ALL_BRAND = 'TOGGLE_ALL_BRAND';
export const TOGGLE_LIVE_AREAS = 'TOGGLE_LIVE_AREAS';
export const SET_SKETCH_ID = 'SET_SKETCH_ID';

export const USER_PERMISSIONS_PENDING = 'USER_PERMISSIONS_LOGIN';
export const USER_PERMISSIONS_SUCCESS = 'USER_PERMISSIONS_SUCCESS';
export const USER_PERMISSIONS_ERROR = 'USER_PERMISSIONS_ERROR';

export const SET_CAPTCHA = 'SET_CAPTCHA';
export const CLEAR_CAPTCHA = 'CLEAR_CAPTCHA';

//Chart Actions.
export const SET_LISTEN_PARAMS = 'SET_LISTEN_PARAMS';
export const UNIQUE_REQUEST_PENDING = 'UNIQUE_REQUEST_PENDING';
export const UNIQUE_REQUEST_SUCCESS = 'UNIQUE_REQUEST_SUCCESS';
export const UNIQUE_REQUEST_ERROR = 'UNIQUE_REQUEST_ERROR';
export const SET_CHART_DATA = 'SET_CHART_DATA';
export const SET_SHOULD_UPDATE = 'SET_SHOULD_UPDATE';
export const REMOVE_ITEM_UNIQUE = 'REMOVE_ITEM_UNIQUE';
export const SET_DATA_UNIQUE_REQUEST = 'SET_DATA_UNIQUE_REQUEST';

//Camera Actions.
export const CLEAR_CAMERA_REDUCER = 'CLEAR_CAMERA_REDUCER';
export const GET_CAMERA_SUCCESS = 'GET_CAMERA_SUCCESS';
export const GET_CAMERA_PENDING = 'GET_CAMERA_PENDING';
export const GET_CAMERA_ERROR = 'GET_CAMERA_ERROR';
export const CAMERA_PHOTO_REQUEST_PENDING = 'CAMERA_PHOTO_REQUEST_PENDING';
export const CAMERA_PHOTO_REQUEST_SUCCESS = 'CAMERA_PHOTO_REQUEST_SUCCESS';
export const CAMERA_PHOTO_REQUEST_ERROR = 'CAMERA_PHOTO_REQUEST_ERROR';
export const CAMERAS_PHOTO_REQUEST_PENDING = 'CAMERAS_PHOTO_REQUEST_PENDING';
export const CAMERAS_PHOTO_REQUEST_SUCCESS = 'CAMERAS_PHOTO_REQUEST_SUCCESS';
export const CAMERAS_PHOTO_REQUEST_ERROR = 'CAMERAS_PHOTO_REQUEST_ERROR';

export const HEATMAPDATA_REQUEST_PENDING = 'REQUEST_HEATMAPDATA_PENDING';
export const HEATMAPDATA_REQUEST_SUCCESS = 'REQUEST_HEATMAPDATA_SUCCESS';
export const HEATMAPDATA_REQUEST_ERROR = 'REQUEST_HEATMAPDATA_ERROR';
export const SETTINGS_SELECTED_CAMERA_CHANGE =
  'SETTINGS_SELECTED_CAMERA_CHANGE';
export const HEATMAP_SELECTED_CAMERA_CHANGE = 'HEATMAP_SELECTED_CAMERA_CHANGE';
export const SELECTED_CAMERA_CHANGE = 'SELECTED_CAMERA_CHANGE';
export const GET_CAMERA_WITH_RECTS_PENDING = 'GET_CAMERA_WITH_RECTS_PENDING';
export const GET_CAMERA_WITH_RECTS_SUCCESS = 'GET_CAMERA_WITH_RECTS_SUCCESS';
export const GET_CAMERA_WITH_RECTS_ERROR = 'GET_CAMERA_WITH_RECTS_ERROR';
export const GET_CAMERA_RECTS_CAM_ID_PENDING =
  'GET_CAMERA_RECTS_CAM_ID_PENDING';
export const GET_CAMERA_RECTS_CAM_ID_SUCCESS =
  'GET_CAMERA_RECTS_CAM_ID_SUCCESS';
export const GET_CAMERA_RECTS_CAM_ID_ERROR = 'GET_CAMERA_RECTS_CAM_ID_ERROR';

//Area Actions.
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
export const GET_AREAS_PENDING = 'GET_AREAS_PENDING';
export const GET_AREAS_ERROR = 'GET_AREAS_ERROR';
export const SELECTED_AREA_CHANGE = 'SELECTED_AREA_CHANGE';
export const AREA_PHOTO_REQUEST_PENDING = 'AREA_PHOTO_REQUEST_PENDING';
export const AREA_PHOTO_REQUEST_SUCCESS = 'AREA_PHOTO_REQUEST_SUCCESS';
export const AREA_PHOTO_REQUEST_ERROR = 'AREA_PHOTO_REQUEST_ERROR';
export const SELECTED_RECT_CHANGE = 'SELECTED_RECT_CHANGE';
export const SET_STATISTIC = 'SET_STATISTIC';
export const SET_COMPANY_SELECT_STORES = 'SET_SELECTSTORES';
export const SET_AREA_RADIO_BUTTONS = 'SET_AREA_RADIO_BUTTONS';
export const DELETE_SELECTED_RECT_NAME = 'DELETE_SELECTED_RECT_NAME';
export const SELECTED_DEPARTMENT_CHANGE = 'SELECTED_DEPARTMENT_CHANGE';
export const UPDATE_AREA = 'UPDATE_AREA';
//Brand Actions
export const GET_CATEGORIES_PENDING = 'GET_CATEGORIES_PENDING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_DEPARTMENTS_PENDING = 'GET_DEPARTMENTS_PENDING';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_ERROR = 'GET_DEPARTMENTS_ERROR';

export const GET_SUB_DEPARTMENTS_PENDING = 'GET_SUB_DEPARTMENTS_PENDING';
export const GET_SUB_DEPARTMENTS_SUCCESS = 'GET_SUB_DEPARTMENTS_SUCCESS';
export const GET_SUB_DEPARTMENTS_ERROR = 'GET_SUB_DEPARTMENTS_ERROR';

export const GET_BRANDS_PENDING = 'GET_BRANDS_PENDING';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';
export const SELECTED_BRAND_CHANGE = 'SELECTED_BRAND_CHANGE';
export const GET_PRODUCTTREE_PENDING = 'GET_PRODUCTTREE_PENDING';
export const GET_PRODUCTTREE_SUCCESS = 'GET_PRODUCTTREE_SUCCESS';
export const GET_PRODUCTTREE_ERROR = 'GET_PRODUCTTREE_ERROR';
export const GET_BRAND_LOGO_PENDING = 'GET_BRAND_LOGO_PENDING';
export const GET_BRAND_LOGO_SUCCESS = 'GET_BRAND_LOGO_SUCCESS';
export const GET_BRAND_LOGO_ERROR = 'GET_BRAND_LOGO_ERROR';

export const SET_BRAND_PRODUCTS = 'SET_BRAND_PRODUCTS';

//Page Actions.
export const GET_PAGE = 'GET_PAGE';
export const FILTER = 'FILTER';
export const URL_SEARCH_PARAMS = 'URL_SEARCH_PARAMS';
export const CHANGE_HAPPENEND = 'CHANGE_HAPPENEND';
export const SET_TRENDS_FILTER_TYPE = 'SET_TRENDS_FILTER_TYPE';
export const GET_PAGE_NOTIFICATIONS = 'GET_PAGE_NOTIFICATIONS';

//Notification Actions
export const GET_NOTRESP_PENDING = 'GET_NOTRESP_PENDING';
export const GET_NOTRESP_SUCCESS = 'GET_NOTRESP_SUCCESS';
export const SET_NOTRESP_BRAND_ID = 'SET_NOTRESP_BRAND_ID';
export const GET_NOTRESP_ERROR = 'GET_NOTRESP_ERROR';

//Board Actions
export const GET_BOARD_PENDING = 'GET_BOARD_PENDING';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';
export const GET_BOARD_ERROR = 'GET_BOARD_ERROR';

//Receiver Actions
export const GET_RECEIVER_PENDING = 'GET_RECEIVER_PENDING';
export const GET_RECEIVER_SUCCESS = 'GET_RECEIVER_SUCCESS';
export const GET_RECEIVER_ERROR = 'GET_RECEIVER_ERROR';

//Personnel Actions
export const GET_PERSONNEL_PENDING = 'GET_PERSONNEL_PENDING';
export const GET_PERSONNEL_SUCCESS = 'GET_PERSONNEL_SUCCESS';
export const GET_PERSONNEL_ERROR = 'GET_PERSONNEL_ERROR';

//Beacon Actions
export const GET_BEACON_PENDING = 'GET_BEACON_PENDING';
export const GET_BEACON_SUCCESS = 'GET_BEACON_SUCCESS';
export const GET_BEACON_ERROR = 'GET_BEACON_ERROR';

//Checkout Actions
export const GET_CHECKOUTS_PENDING = 'GET_CHECKOUTS_PENDING';
export const GET_CHECKOUTS_SUCCESS = 'GET_CHECKOUTS_SUCCESS';
export const GET_CHECKOUTS_ERROR = 'GET_CHECKOUTS_ERROR';

//Report
export const GET_ENTRANCESUM_DATA_PENDING = 'GET_ENTRANCESUM_DATA_PENDING';
export const GET_ENTRANCESUM_DATA_SUCCESS = 'GET_ENTRANCESUM_DATA_SUCCESS';
export const GET_ENTRANCESUM_DATA_ERROR = 'GET_ENTRANCESUM_DATA_ERROR';
export const GET_CHECKOUT_DATA_PENDING = 'GET_CHECKOUT_DATA_PENDING';
export const GET_CHECKOUT_DATA_SUCCESS = 'GET_CHECKOUT_DATA_SUCCESS';
export const GET_CHECKOUT_DATA_ERROR = 'GET_CHECKOUT_DATA_ERROR';
export const TOGGLE_SHOW_NAVBAR_ON_SMALL_SCREEN =
  'TOGGLE_SHOW_NAVBAR_ON_SMALL_SCREEN';
