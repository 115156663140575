import 'core-js'; // Polyfills
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './ReduxStore';
import App from './Containers/App';
import {
  is_exist_x_session_id,
  create_x_session_id
} from './Helper/x_session_id_creator';
import register from './registerServiceWorker';

class MainApp extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Provider store={store}>
            <Route path="" component={App} />
          </Provider>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<MainApp />, document.getElementById('root'));
register();

// Define new User Agent if it does not exist.
if (!is_exist_x_session_id()) {
  create_x_session_id();
}
