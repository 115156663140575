import React from 'react';
import { Transition } from 'react-transition-group';
import UError from '../UError';
import ULoading from '../ULoading';
import './style.scss';
function ContentOverlayLoader({
  children,
  error = 'Bir Hata Oluştu.',
  status,
  customError
}) {
  return (
    <div className="content-overlay-loader">
      {(status === 'Rejected' || status === 'Pending') && (
        <div className="content-overlay-loader-overlay">
          {status === 'Pending' && (
            <Transition in={true} timeout={200} unmountOnExit>
              {status => <ULoading className={`fade-${status}`} />}
            </Transition>
          )}
          {status === 'Rejected' && (customError ?? <UError text={error} />)}
        </div>
      )}
      {children}
    </div>
  );
}

export default ContentOverlayLoader;
