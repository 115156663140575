import React from 'react';
import './style.scss';
import { useTranslation } from '../../../Hooks/useTranslation';
import { Button } from 'react-bootstrap';

export function Stepper(props) {
  const { step, onNext, onBack, maxStep, onSubmit, isLoading, isReadonly } =
    props;

  const { t } = useTranslation();

  return (
    <div className="form-stepper">
      <div className="current-step-indicator">
        {step + 1} / {maxStep + 1}
      </div>

      {step > 0 && (
        <Button disabled={isLoading} onClick={onBack} variant="secondary">
          {t('global.back')}
        </Button>
      )}
      {step < maxStep && (
        <Button disabled={isLoading} onClick={onNext} type="submit">
          {t('global.next')}
        </Button>
      )}
      {step === maxStep && !isReadonly && (
        <Button disabled={isLoading} onClick={onSubmit} type="submit">
          {t('global.submit')}
        </Button>
      )}
    </div>
  );
}
