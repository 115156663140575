import React, { Component } from 'react';
import './style.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import { formatDistance } from 'date-fns';
import { dig } from '../../Helper/commons';
import UModal from '../Modal';
import { setParamModal } from '../../ReduxActions/paramsActions';
import { SetUserNotificationResponse } from '../../Helper/api';
import { getNotResponses } from '../../ReduxActions/storeActions';
import { getNotifications } from '../../ReduxActions/userActions';
import { findIsTimedOut, humanizeNotificationDate } from './utils';

class NotificationChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationResponse: false,
      selectedNot: {}
    };
  }

  handleScroll(e) {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop < element.clientHeight + 50) {
      this.props.newReqest({ lastItem: this.props.data.at(-1) });
    }
  }

  DateDistanceFormatter(prevDate) {
    const dateLocale =
      this.props.lang === 'tr' ? tr : this.props.lang === 'de' ? de : en;

    const timezone = new Date().getTimezoneOffset();

    const result = formatDistance(
      new Date(new Date(prevDate).getTime() + timezone * 60000),
      new Date(),
      {
        locale: dateLocale
      }
    );
    return result;
  }
  responseOnClick(selectNot) {
    if (!this.props.notificationResponses.pending) {
      this.props.getNotResponses(selectNot.BrandId);
    }

    this.setState({
      selectedNot: selectNot,
      showNotificationResponse: true
    });
  }

  ResponseOnCancel() {
    this.setState({
      selectedNot: {},
      showNotificationResponse: false
    });
  }

  async setAnswerNotResp(selectedResponse) {
    try {
      await SetUserNotificationResponse({
        NotificationId: this.state.selectedNot.Id,
        Response: selectedResponse?.Text,
        NotificationSenderType: this.state.selectedNot.NotificationSenderType
      });

      this.props.newReqest({
        lastItem: this.props.data.at(-1),
        resetNotifications: true
      });

      this.setState({
        selectedNot: {},
        showNotificationResponse: false
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Scrollbars
        onScroll={this.handleScroll.bind(this)}
        autoHeight
        autoHeightMax={this.props.maxHeight}
      >
        <div
          className="NotificationChilds"
          onScroll={this.handleScroll.bind(this)}
        >
          {this.props.data.map((d, i) => {
            return (
              <div key={i} className="NotificationChild_wrapper">
                <div className="Div_as__border"></div>
                <div className="Notification_section">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 Notification_Title">
                      {d.Store + ' - ' + d.RuleSetName}
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                      {!(d.Seen === true || !!d.SeenDate) && (
                        <div className="lolipop"></div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 Notification_Content">
                      {d.Message}
                      {d.Response && (
                        <div>
                          <label>
                            {this.props.intl.formatMessage({
                              id: 'notification.response'
                            })}{' '}
                            {d.Response}
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4">
                      <div
                        className="Notification_Date"
                        title={humanizeNotificationDate(d.CreateDate)}
                      >
                        {' '}
                        {this.DateDistanceFormatter(d.CreateDate)}
                      </div>
                      {!d.Response &&
                        (findIsTimedOut(d) ? (
                          <div className="timed-out-info">
                            <FormattedMessage id="notification.timedOut" />
                          </div>
                        ) : (
                          <div
                            className="Notification_Answer"
                            onClick={this.responseOnClick.bind(this, d)}
                          >
                            <FormattedMessage id="notification.reply" />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {
            <AnswerNotificationResponseModal
              show={this.state.showNotificationResponse}
              selectNotData={this.state.selectedNot}
              onCancel={this.ResponseOnCancel.bind(this)}
              setAnswerNotResp={this.setAnswerNotResp.bind(this)}
              notificationResponses={this.props.notificationResponses}
            />
          }
          {this.props.Animation && !this.props.atLastNotification && (
            <div className="Ring_container">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {!this.props.Animation && this.props.data.length === 0 && (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 NotificationNotAvaliableData">
                <div className="Error_message">
                  <FormattedMessage id="HomeBrand.Info.emptyValues" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Scrollbars>
    );
  }
}

const mapStateToProps = state => ({
  allCameras: state.camera.allCameras,
  lang: dig(state.user, 'user', 'details', 'SelectedLanguage'),
  Modal: state.params.modal,
  brandId: state.store.selectedBrand.globalBrandParam.Id,
  notificationResponses: state.brand.notificationResponses.data
});

const mapDispatchToProps = dispatch => ({
  setParamModal: (paramName, value) =>
    dispatch(setParamModal(paramName, value)),
  getNotResponses: brandId => dispatch(getNotResponses(brandId)),
  getNotifications: (p, resetNotifications) =>
    dispatch(getNotifications(p, resetNotifications))
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(NotificationChild)
);

class _AnswerNotificationResponseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedResponse: null,
      otherResponse: ''
    };
  }
  setAnswerNot() {
    const { notificationResponses } = this.props;
    const selectedNotResponse = notificationResponses.find(
      resp => resp.Id === this.state.selectedResponse
    );
    let Response = { ...selectedNotResponse };
    if (this.state.selectedResponse === -1) {
      Response = { ...selectedNotResponse, Text: this.state.otherResponse };
    }
    this.props.setAnswerNotResp(Response);
    this.setState({
      selectedResponse: null
    });
  }
  inputOnChange(responseId) {
    this.setState({
      selectedResponse: responseId,
      otherResponse: ''
    });
  }
  handlerValueChange(e) {
    this.setState({
      otherResponse: e.target.value
    });
  }

  render() {
    const { onCancel, selectNotData, show, notificationResponses } = this.props;
    const options = JSON.parse(JSON.stringify(notificationResponses));
    options.push({
      value: -1,
      Id: -1,
      Text: `${this.props.intl.formatMessage({
        id: 'notification.otherResponse'
      })}`
    });

    return (
      <UModal
        className="AnswerModal"
        show={show}
        Title={selectNotData.Message}
        size="md"
        removeClass={true}
        cancel={onCancel}
        disableFooter={true}
        confirm={this.setAnswerNot.bind(this, selectNotData)}
        disabledConfirm={!this.state.selectedResponse}
      >
        <div className="row" id="AnswerNotification">
          <div className="col-xl-12">
            <div className=" row">
              {options.map(response => (
                <div className="FilterForm__ShowInput col-12" key={response.Id}>
                  <label>
                    {response.Text}
                    <input
                      checked={response.Id === this.state.selectedResponse}
                      onChange={this.inputOnChange.bind(
                        this,
                        response.Id,
                        response.Text
                      )}
                      type="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                  {response.Id === -1 && this.state.selectedResponse === -1 && (
                    <div className="otherText ml-3">
                      <input
                        className="otherInputText"
                        type="text"
                        value={this.state.otherResponse}
                        onChange={this.handlerValueChange.bind(this)}
                      />
                    </div>
                  )}
                </div>
              ))}
              {notificationResponses.length == 0 && (
                <div className="ml-3">
                  <FormattedMessage id="notification.emptyResponse" />
                </div>
              )}
            </div>
          </div>
        </div>
      </UModal>
    );
  }
}
const AnswerNotificationResponseModal = injectIntl(
  _AnswerNotificationResponseModal
);
