import React, { Component } from 'react';
import './style.css';

export default class UError extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="Error__Wrapper">
          <div className="Error__Text">{this.props.text}</div>
        </div>
      </React.Fragment>
    );
  }
}
