import {
  USER_REQUEST_LOGIN_PENDING,
  USER_REQUEST_LOGIN_SUCCESS,
  USER_REQUEST_LOGIN_ERROR,
  RESET_USER_STATE,
  GET_NOTIFICATIONS_PENDING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATION_COUNT,
  RESET_NOTIFICATION_COUNT,
  RESET_NOTIFICATIONS,
  SET_USER_DETAILS,
  GET_PATH,
  INITIALIZE_STATE,
  TOGGLE_NOMINAL,
  TOGGLE_LIVE_AREAS,
  USER_PERMISSIONS_PENDING,
  USER_PERMISSIONS_SUCCESS,
  USER_PERMISSIONS_ERROR,
  CLEAR_CAPTCHA,
  SET_CAPTCHA,
  SET_LAST_FETCHED_NOTIFICATION_COUNT
} from '../ReduxActions/types';

let userLang = navigator.language || navigator.userLanguage;
userLang = userLang.split('-')[0];
if (userLang !== 'tr') {
  userLang = 'en';
}

var initialState = {
  pending: false,
  success: false,
  error: null,
  pathName: null,
  Notifications: {
    data: [],
    success: false,
    pending: false,
    atLast: false
  },
  NotificationsCount: 0,
  showNominal: false,
  showLiveAreas: false,
  Permissions: {
    data: {},
    success: false,
    pending: false,
    error: false
  },
  Captcha: {
    required: false,
    image: undefined,
    expire: undefined
  }
};

export default function (state = null, action) {
  let nextState = { ...state };
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState,
        user: JSON.parse(localStorage.getItem('user')) || {}
      };
      break;
    case USER_REQUEST_LOGIN_PENDING:
      nextState = {
        ...state,
        pending: true,
        success: false
      };
      break;
    case RESET_USER_STATE:
      nextState = {
        ...state,
        pending: false,
        success: false,
        error: null
      };
      break;
    case USER_REQUEST_LOGIN_SUCCESS:
      nextState = {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
        pending: false,
        success: true
      };
      break;
    case USER_REQUEST_LOGIN_ERROR:
      nextState = {
        ...state,
        success: false,
        pending: false,
        error: action.payload
      };
      break;
    case SET_USER_DETAILS:
      var details = { ...action.payload };
      if (!action.payload.SelectedLanguage)
        details['SelectedLanguage'] = userLang;
      nextState = {
        ...state,
        user: {
          ...state.user,
          details: details
        }
      };
      localStorage.setItem('user', JSON.stringify(nextState.user));
      break;
    case GET_PATH:
      nextState = {
        ...state,
        pathName: action.payload
      };
      break;
    case GET_NOTIFICATIONS_PENDING:
      nextState['Notifications'] = {
        ...state['Notifications'],
        pending: true,
        success: false
      };
      break;
    case RESET_NOTIFICATIONS:
      nextState['Notifications'].data = [];
      nextState['Notifications'] = {
        data: action.payload,
        pending: false,
        success: true,
        atLast: false
      };
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      nextState['Notifications'] = {
        ...state['Notifications'],
        data: [
          ...state['Notifications'].data.map(n => ({ ...n, Seen: true })),
          ...action.payload
        ].sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate)),
        atLast: action.atLast,
        pending: false,
        success: true,
        lastFetchedNotificationCount: action.lastFetchedNotificationCount
      };
      break;
    case GET_NOTIFICATIONS_ERROR:
      nextState['Notifications'] = {
        ...state['Notifications'],
        pending: false,
        success: true,
        error: action.payload,
        atLast: false
      };
      break;
    case SET_LAST_FETCHED_NOTIFICATION_COUNT:
      nextState['Notifications'] = {
        ...state['Notifications'],
        lastFetchedNotificationCount: action.payload
      };
      break;
    case SET_NOTIFICATION_COUNT:
      nextState['NotificationsCount'] = action.payload;
      break;
    case RESET_NOTIFICATION_COUNT:
      nextState['NotificationsCount'] = 0;
      break;
    case TOGGLE_NOMINAL:
      nextState['showNominal'] = !state.showNominal;
      break;
    case TOGGLE_LIVE_AREAS:
      nextState['showLiveAreas'] = !state.showLiveAreas;
      // case SET_LANGUAGE:
      //     nextState['lang'] = action.payload;
      //     localStorage.setItem('lang', action.payload)
      //     break;
      // case SET_TOUR_INFO:
      //     nextState['tourInfo'] = action.payload;
      //     break;
      break;
    case USER_PERMISSIONS_PENDING:
      nextState['Permissions'] = {
        ...state['Permissions'],
        data: {},
        pending: true,
        success: false,
        error: false
      };
      break;
    case USER_PERMISSIONS_SUCCESS:
      nextState['Permissions'] = {
        ...state['Permissions'],
        data: action.payload,
        pending: false,
        success: true
      };
      break;
    case USER_PERMISSIONS_ERROR:
      nextState['Permissions'] = {
        ...state['Permissions'],
        data: {},
        pending: false,
        error: true
      };
      break;
    case SET_CAPTCHA:
      nextState['Captcha'] = {
        ...state['Captcha'],
        required: true,
        image: action.payload.image,
        expire: action.payload.expire
      };
      break;
    case CLEAR_CAPTCHA:
      nextState['Captcha'] = {
        ...state['Captcha'],
        required: false,
        image: undefined,
        expire: undefined
      };
      break;
    default:
      break;
  }
  return nextState;
}
