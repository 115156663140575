import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function useTranslation() {
  const { formatMessage, ...restUtils } = useIntl();

  const translate = useCallback(
    (translationKey, values, options) => {
      return formatMessage({ id: translationKey, options }, values);
    },
    [formatMessage]
  );

  return {
    t: translate,
    ...restUtils
  };
}
