import React, { useState } from 'react';
import './style.scss';

const RadioButton = React.forwardRef((props, ref) => {
  const [id] = useState(Math.random());

  const {
    className,
    label,
    value,
    onChange,
    checked,
    isDisabled,
    ...restProps
  } = props;

  return (
    <div
      className={`form-radio-button ${isDisabled ? 'disabled' : ''}`}
      onClick={() => onChange && !isDisabled && onChange(value)}
    >
      <input
        ref={ref}
        type="radio"
        id={id}
        value={value}
        checked={checked}
        {...restProps}
      />
      <span className="form-radio-circle checkmark" />
      <label className="form-radio-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

const RadioButtons = React.forwardRef((props, ref) => {
  const { options, value, ...restProps } = props;

  return (
    <div className="form-radio-buttons">
      {options.map(option => (
        <RadioButton
          key={option.value}
          ref={ref}
          {...restProps}
          {...option}
          checked={value === option.value}
        />
      ))}
    </div>
  );
});

export { RadioButton, RadioButtons };
