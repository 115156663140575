import {
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_PENDING,
  GET_DEPARTMENTS_PENDING,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_SUB_DEPARTMENTS_PENDING,
  GET_SUB_DEPARTMENTS_SUCCESS,
  GET_SUB_DEPARTMENTS_ERROR,
  INITIALIZE_STATE,
  RESET_STATE,
  GET_PRODUCTTREE_PENDING,
  GET_PRODUCTTREE_SUCCESS,
  GET_PRODUCTTREE_ERROR,
  GET_NOTRESP_PENDING,
  GET_NOTRESP_SUCCESS,
  GET_NOTRESP_ERROR,
  GET_BRAND_LOGO_PENDING,
  GET_BRAND_LOGO_SUCCESS,
  GET_BRAND_LOGO_ERROR,
  SET_BRAND_PRODUCTS,
  SET_NOTRESP_BRAND_ID
} from '../ReduxActions/types';

var initialState = {
  categories: {
    pending: false,
    success: false,
    data: null
  },
  departments: {
    pending: false,
    valid: false,
    data: [],
    error: false
  },
  subDepartments: {
    pending: false,
    success: false,
    data: []
  },
  productTree: {
    pending: false,
    success: false,
    data: [],
    error: false
  },
  notificationResponses: {
    pending: false,
    success: false,
    data: [],
    error: false,
    brandId: undefined
  },
  brandLogo: {
    pending: false,
    success: false,
    data: [],
    error: false
  },
  Products: []
};

export default function (state = null, action) {
  let nextState = {};
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_CATEGORIES_PENDING:
      nextState = {
        ...state,
        categories: {
          pending: true,
          success: false,
          data: null
        }
      };
      break;
    case GET_CATEGORIES_SUCCESS:
      nextState = {
        ...state,
        categories: {
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_CATEGORIES_ERROR:
      nextState = {
        ...state,
        categories: {
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    case GET_DEPARTMENTS_PENDING:
      nextState = {
        ...state,
        departments: {
          ...state.departments,
          pending: true,
          valid: false,
          data: [],
          error: false
        }
      };
      break;
    case GET_DEPARTMENTS_SUCCESS:
      nextState = {
        ...state,
        departments: {
          ...state.departments,
          pending: false,
          valid: true,
          data: action.payload
        }
      };
      break;
    case GET_DEPARTMENTS_ERROR:
      nextState = {
        ...state,
        departments: {
          ...state.departments,
          pending: false,
          valid: true,
          data: [],
          error: action.payload
        }
      };
      break;
    case GET_SUB_DEPARTMENTS_PENDING:
      nextState = {
        ...state,
        subDepartments: {
          pending: true,
          valid: false,
          data: []
        }
      };
      break;
    case GET_SUB_DEPARTMENTS_SUCCESS:
      nextState = {
        ...state,
        subDepartments: {
          pending: false,
          valid: true,
          data: action.payload
        }
      };
      break;
    case GET_SUB_DEPARTMENTS_ERROR:
      nextState = {
        ...state,
        subDepartments: {
          pending: false,
          valid: true,
          error: action.payload
        }
      };
      break;
    case GET_PRODUCTTREE_PENDING:
      nextState = {
        ...state,
        productTree: {
          ...state.productTree,
          pending: true,
          success: false,
          error: false,
          data: []
        }
      };
      break;
    case GET_PRODUCTTREE_SUCCESS:
      nextState = {
        ...state,
        productTree: {
          ...state.productTree,
          pending: false,
          success: true,
          data: action.payload
        }
      };
      break;
    case GET_PRODUCTTREE_ERROR:
      nextState = {
        ...state,
        brandLogo: {
          ...state.departments,
          pending: true,
          valid: false,
          data: [],
          error: false
        }
      };
      break;
    case GET_BRAND_LOGO_PENDING:
      nextState = {
        ...state,
        brandLogo: {
          ...state.brandLogo,
          pending: true,
          success: false,
          error: false,
          data: []
        }
      };
      break;
    case GET_BRAND_LOGO_ERROR:
      nextState = {
        ...state,
        brandLogo: {
          pending: false,
          valid: true,
          error: action.payload
        }
      };
      break;
    case GET_BRAND_LOGO_SUCCESS:
      nextState = {
        ...state,
        brandLogo: {
          ...state.brandLogo,
          pending: false,
          valid: true,
          data: action.payload
        }
      };
      break;

    case SET_NOTRESP_BRAND_ID:
      nextState = {
        ...state,
        notificationResponses: {
          ...state.notificationResponses,
          brandId: action.payload
        }
      };
      break;

    case GET_NOTRESP_PENDING:
      nextState = {
        ...state,
        notificationResponses: {
          ...state.notificationResponses,
          pending: true,
          success: false,
          error: false,
          data: []
        }
      };
      break;
    case GET_NOTRESP_SUCCESS:
      nextState = {
        ...state,
        notificationResponses: {
          ...state.notificationResponses,
          pending: false,
          success: true,
          brandId: action.payload.brandId,
          data: action.payload.data
        }
      };
      break;
    case GET_NOTRESP_ERROR:
      nextState = {
        ...state,
        notificationResponses: {
          ...state.notificationResponses,
          data: [],
          pending: false,
          success: true,
          error: action.payload,
          brandId: undefined
        }
      };
      break;
    case SET_BRAND_PRODUCTS:
      nextState = {
        ...state,
        Products: action.payload
      };
      break;
    default:
      return state;
  }
  return nextState;
}
