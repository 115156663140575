import {
  GET_BEACON_SUCCESS,
  GET_BEACON_PENDING,
  GET_BEACON_ERROR
} from './types';
import { getBeacons } from '../Helper/api';

export const getAllBeacons = storeID => dispatch => {
  dispatch({
    type: GET_BEACON_PENDING
  });
  getBeacons(storeID)
    .then(res =>
      dispatch({
        type: GET_BEACON_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_BEACON_ERROR,
        payload: err
      });
    });
};
