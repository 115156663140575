import {
  GET_ENTRANCESUM_DATA_PENDING,
  GET_ENTRANCESUM_DATA_SUCCESS,
  GET_ENTRANCESUM_DATA_ERROR,
  GET_CHECKOUT_DATA_ERROR,
  GET_CHECKOUT_DATA_SUCCESS,
  GET_CHECKOUT_DATA_PENDING,
  INITIALIZE_STATE,
  RESET_STATE
} from '../ReduxActions/types';

var initialState = {
  entranceSummary: {
    pending: false,
    valid: false,
    data: {},
    error: false
  },
  checkoutSummary: {
    pending: false,
    valid: false,
    data: {},
    error: false
  }
};

export default function (state = null, action) {
  let nextState = {};
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_ENTRANCESUM_DATA_PENDING:
      nextState = {
        ...state,
        entranceSummary: {
          ...state,
          data: {},
          pending: true,
          success: false,
          data: null
        }
      };
      break;
    case GET_ENTRANCESUM_DATA_SUCCESS:
      nextState = {
        ...state,
        entranceSummary: {
          ...state.entranceSummary,
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_ENTRANCESUM_DATA_ERROR:
      nextState = {
        ...state,
        entranceSummary: {
          ...state.entranceSummary,
          data: {},
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;

    case GET_CHECKOUT_DATA_PENDING:
      nextState = {
        ...state,
        checkoutSummary: {
          ...state,
          data: {},
          pending: true,
          success: false,
          data: null
        }
      };
      break;
    case GET_CHECKOUT_DATA_SUCCESS:
      nextState = {
        ...state,
        checkoutSummary: {
          ...state.checkoutSummary,
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_CHECKOUT_DATA_ERROR:
      nextState = {
        ...state,
        checkoutSummary: {
          ...state.checkoutSummary,
          data: {},
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    default:
      return state;
  }
  return nextState;
}
