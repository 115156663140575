import { version } from '../package.json';

export default {
  apibase: process.env.REACT_APP_API_ENDPOINT
    ? process.env.REACT_APP_API_ENDPOINT
    : 'https://apitest.udentify.co',
  socketUrl: process.env.REACT_APP_SOCKETURL
    ? process.env.REACT_APP_SOCKETURL
    : 'https://rtm.udentify.co/nothub',
  hasRTM: process.env.REACT_APP_SIGNALR
    ? process.env.REACT_APP_SIGNALR === 'true'
    : true,
  isTestEnv: process.env.REACT_APP_ISTEST === 'true',
  version: version
};
