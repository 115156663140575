import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { setParamModal } from '../../ReduxActions/paramsActions';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import UButton from '../UButton';
class UModal extends Component {
  componentWillUnmount() {
    this.props.setParamModal('modal', false);
  }

  closeModal = () => {
    if (!this.props.isLocal) {
      this.props.setParamModal('modal', false);
    }
    this.props.cancel && this.props.cancel();
  };

  confirm = () => {
    if (!this.props.isLocal) {
      this.props.setParamModal('modal', false);
    }
    this.props.confirm && this.props.confirm();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.closeModal}
        size={this.props.size ? this.props.size : 'lg'}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        className={`${!this.props.removeClass && 'New-Overlay'} ${
          this.props.className
        }`}
      >
        {!this.props.disableHeader && (
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              {this.props.Title}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{this.props.children}</Modal.Body>
        {(this.props.confirm || !this.props.disableFooter) && (
          <Modal.Footer>
            {this.props.confirm && (
              <UButton
                disabled={this.props.disabledConfirm}
                color="primary"
                className="Primary"
                value={<FormattedMessage id="info.confirm" />}
                onClick={this.confirm}
                {...this.props.confirmButtonProps}
              />
            )}
            {!this.props.disableFooter && (
              <UButton
                className="Primary"
                value={<FormattedMessage id="modal.close" />}
                onClick={this.closeModal}
              />
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  params: state.params
});

const mapDispatchToProps = dispatch => ({
  setParamModal: (uniqName, value) => dispatch(setParamModal(uniqName, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(UModal);
