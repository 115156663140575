import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import UButton from '../UButton';
import { Transition } from 'react-transition-group';

class UPrompt extends Component {
  cancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  confirm() {
    this.props.onConfirm();
  }
  render() {
    const { show } = this.props;
    return (
      <Transition in={show} timeout={200} unmountOnExit>
        {status => (
          <div className={`fade-${status} uPrompt__Wrapper`}>
            <div className="Overlay" onClick={this.cancel.bind(this)} />
            <div
              className={'uPrompt__Container ' + (this.props.className ?? '')}
            >
              <div className="uPrompt__Text">{this.props.text}</div>
              <div className="uPrompt__Buttons">
                {!this.props.removeCancel && (
                  <UButton
                    className="uButton default link"
                    onClick={this.cancel.bind(this)}
                    value={<FormattedMessage id="info.cancel" />}
                  />
                )}
                {!this.props.removeConfirm && (
                  <UButton
                    className="uButton Primary"
                    pending={this.props.pending}
                    icon="loadingbutton"
                    onClick={this.confirm.bind(this)}
                    value={<FormattedMessage id="info.confirm" />}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Transition>
    );
  }
}

export default UPrompt;
