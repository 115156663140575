import React, { useMemo, cloneElement } from 'react';
import { useSelector } from 'react-redux';
function PermissionCheck({ requiredRole, children, fallback, override }) {
  const { data, pending, success } = useSelector(
    state => state.user.Permissions
  );
  const { IsCustomRole } = useSelector(state => state.user.user.details);
  const show = useMemo(
    () =>
      success && !IsCustomRole
        ? true
        : data.Permissions.some(permission => permission.Name === requiredRole),
    [success]
  );

  if (override) {
    let childOverrideProps = {};
    if (!show) {
      childOverrideProps = {
        onClick: () => {},
        href: undefined,
        style: { userSelect: 'none', cursor: 'default' }
      };
    }
    return cloneElement(children, childOverrideProps);
  }
  return show ? children : fallback ? fallback : <></>;
}

export default PermissionCheck;
