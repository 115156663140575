import {
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_PENDING,
  GET_DEPARTMENTS_PENDING,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_SUB_DEPARTMENTS_PENDING,
  GET_SUB_DEPARTMENTS_SUCCESS,
  GET_SUB_DEPARTMENTS_ERROR,
  GET_PRODUCTTREE_PENDING,
  GET_PRODUCTTREE_SUCCESS,
  GET_PRODUCTTREE_ERROR,
  GET_BRAND_LOGO_PENDING,
  GET_BRAND_LOGO_SUCCESS,
  GET_BRAND_LOGO_ERROR
} from './types';

import {
  GetCategories,
  GetAllDepartments,
  brandProductTree,
  getBrandLogo
} from '../Helper/api';
import { getPageNotification } from './pageActions';
import { Products } from '../Constants';

export const getCategories = BrandId => dispatch => {
  dispatch({
    type: GET_CATEGORIES_PENDING
  });

  return GetCategories(BrandId)
    .then(res => {
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: GET_CATEGORIES_ERROR,
        payload: err
      });
    });
};

export const getDepartments = BrandId => (dispatch, getState) => {
  const { brand } = getState();
  dispatch({
    type: GET_DEPARTMENTS_PENDING
  });
  dispatch({
    type: GET_SUB_DEPARTMENTS_PENDING
  });
  return GetAllDepartments(BrandId)
    .then(res => {
      var SubDeps = [];
      dispatch({
        type: GET_DEPARTMENTS_SUCCESS,
        payload: res.map(x => {
          x.SubDepartments.map(sd => {
            SubDeps.push(sd);
          });
          return { value: x.Id, label: x.Name, res: x };
        })
      });

      dispatch({
        type: GET_SUB_DEPARTMENTS_SUCCESS,
        payload: SubDeps.map(x => ({ value: x.Id, label: x.Name, res: x }))
      });
      if (
        res.length === 0 &&
        brand?.Products[Products.InstoreAnalyticsProduct]
      ) {
        dispatch(
          getPageNotification({
            Id: Date.now(),
            Header: 'membership.Brand',
            message: 'notification.brandWarning',
            navigate: {
              url: '/Settings/Department',
              text: 'notification.brandAdd'
            }
          })
        );
      }
    })
    .catch(err => {
      dispatch({
        type: GET_DEPARTMENTS_ERROR,
        payload: err
      });
      dispatch({
        type: GET_SUB_DEPARTMENTS_ERROR,
        payload: err
      });
    });
};

export const getBrandProductTree = BrandId => dispatch => {
  dispatch({
    type: GET_PRODUCTTREE_PENDING
  });

  return brandProductTree({ BrandId: BrandId })
    .then(res => {
      dispatch({
        type: GET_PRODUCTTREE_SUCCESS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PRODUCTTREE_ERROR,
        payload: err
      });
    });
};

export const getBrandsLogo = BrandId => dispatch => {
  dispatch({
    type: GET_BRAND_LOGO_PENDING
  });

  return getBrandLogo(BrandId)
    .then(res => {
      dispatch({
        type: GET_BRAND_LOGO_SUCCESS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: GET_BRAND_LOGO_ERROR,
        payload: err
      });
    });
};
