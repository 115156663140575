import { useState, useEffect } from 'react';
import Status from '../Constants/Status';

function useRequest(
  request,
  params,
  initleData,
  isDisabled = false,
  customDependencyArr = undefined
) {
  const [result, setResult] = useState({
    data: initleData,
    status: Status.Empty,
    ErrorMessage: ''
  });

  useEffect(() => {
    if (!isDisabled) {
      RequestFlow();
    }
  }, [...(customDependencyArr ?? params), isDisabled]);

  const RequestFlow = () => {
    setResult(prevResult => ({
      ...prevResult,
      data: initleData,
      status: Status.Pending,
      ErrorMessage: ''
    }));
    request(...params)
      .then(res => {
        setResult(prevResult => ({
          ...prevResult,
          status: Status.Fullfilled,
          data: res
        }));
      })
      .catch(err => {
        setResult(prevResult => ({
          ...prevResult,
          status: Status.Rejected,
          ErrorMessage: err
        }));
      });
  };
  return {
    data: result.data,
    status: result.status,
    ErrorMessage: result.ErrorMessage,
    update: RequestFlow
  };
}

export default useRequest;
