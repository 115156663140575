import React, { useEffect, useState } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import LanguageMessages from '../Contexts/LanguageMessages';
import { connect } from 'react-redux';
import { dig } from '../Helper/commons';
import LoginLayout from './LoginLayout';
import PromptConfirm from '../Components/PromptConfirm';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import loadable from '@loadable/component';
import Unsubscribe from '../Pages/Unsubscribe';
import { AxiosInstance } from '../Helper/api';
import { SERVICE_LANGUAGE } from '../Constants/Language';
const LoginComponent = loadable(() => import('../Components/Login'));
const ForgetPassword = loadable(() => import('../Components/ForgetPassword'));
const Layout = loadable(() => import('./Layout'));
const ResetPassword = loadable(() => import('../Components/ResetPassword'));
const RegisterComponent = loadable(() => import('../Components/Register'));
const RedirectLiveCountPage = loadable(() =>
  import('../Pages/RedirectLiveCountPage')
);
const View = loadable(() => import('../Pages/View'));

// import LoginComponent from '../Components/Login';
// import ForgetPassword from '../Components/ForgetPassword';
// import Layout from './Layout';
// import ResetPassword from '../Components/ResetPassword';
// import RegisterComponent from '../Components/Register';

const App = props => {
  const [lang, setlang] = useState('en');
  useEffect(() => {
    var _lang = dig(props, 'userDetails', 'SelectedLanguage');
    if (!_lang) {
      _lang = (navigator.language || navigator.userLanguage).split('-')[0];
      if (_lang !== 'tr') {
        _lang = 'en';
      }
    }
    setlang(_lang);
    AxiosInstance.defaults.headers.common['accepted_lang'] =
      SERVICE_LANGUAGE[_lang];
  }, [dig(props, 'userDetails', 'SelectedLanguage')]);
  const LoginLayoutWithLang = comp => {
    return LoginLayout(comp, setlang);
  };
  return (
    <IntlProvider locale={lang} messages={LanguageMessages[lang]}>
      <React.Fragment>
        <Switch>
          <Route
            path="/Login"
            component={LoginLayoutWithLang(LoginComponent)}
          />
          <Route
            path="/Account/ResetPassword"
            component={LoginLayoutWithLang(ResetPassword)}
          />
          <Route
            path="/ForgetPassword"
            component={LoginLayoutWithLang(ForgetPassword)}
          />
          <Route
            path="/Register"
            component={LoginLayoutWithLang(RegisterComponent)}
          />
          <Route path={'/LiveCount'} component={RedirectLiveCountPage} />
          <Route path={'/View/:View'} component={View} />
          <Route
            path={'/Unsubscribe'}
            component={LoginLayoutWithLang(Unsubscribe)}
          />
          <Route path={'/'} component={Layout} exact={false} />
        </Switch>
        {/* {props.confirm && (
          <PromptConfirm
              confirmCallback={props.confirmCallback}
              setConfirm={props.setConfirm}
          />
         )} */}
      </React.Fragment>
    </IntlProvider>
  );
};

const mapStateToProps = state => ({
  userDetails: dig(state.user, 'user', 'details'),
  user: state.user.user
});

export default withRouter(connect(mapStateToProps, null)(App));
