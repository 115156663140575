export const DepartmentRectangleType = {
  Shelf: 0,
  DoorOuter: 1,
  DoorInner: 2,
  Checkout: 3,
  Ignore: 5,
  DoorLeft: 6,
  DoorRight: 7,
  AreaOfInterest: 8,
  YellowArea: 9,
  Correlation: 10,
  CheckoutWaiting: 11,
  CustomerServices: 12,
  FirstStand: 13
};

export const XS_MEDIA_QUERY = '(max-width: 600px)';

export const lineTypes = {
  PassingStore: 0,
  InStoreOrientation: 1,
  AgeGender: 2,
  FloorStaircase: 5,
  FloorElevator: 6,
  InFloorOrientation: 7,
  PassingShopInShop: 8, // todo
  ShopInShopEntrance: 9 // todo
};

export const lineDirectionTypes = {
  Left: 0,
  Right: 1,
  Middle: 2
};

export const ALL_OPTION = 'all';

export const NotificationSenderType = {
  Mail: 0,
  App: 1,
  Web: 2
};

export const SeenFilter = {
  SEEN: 'seen',
  NON_SEEN: 'non-seen',
  ALL: 'all-seen'
};

export const RespondedFilter = {
  RESPONDED: 'responded',
  NON_RESPONDED: 'non-responded',
  ALL: 'all-responded'
};
