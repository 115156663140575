import {
  GET_RECEIVER_PENDING,
  GET_RECEIVER_SUCCESS,
  GET_RECEIVER_ERROR
} from './types';
import { GetReceivers } from '../Helper/api';

export const getAllReceivers = storeID => dispatch => {
  dispatch({
    type: GET_RECEIVER_PENDING
  });
  GetReceivers(storeID)
    .then(res =>
      dispatch({
        type: GET_RECEIVER_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_RECEIVER_ERROR,
        payload: err
      });
    });
};
