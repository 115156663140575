class timezone {
  /**
   *
   * @returns number
   */
  getLocalTimezone = () => {
    return new Date().getTimezoneOffset() * -1;
  };
  offset = parseInt(
    localStorage?.CustomTimeZoneOffset
      ? localStorage.CustomTimeZoneOffset
      : this.getLocalTimezone()
  );
  constructor() {
    if (timezone._instance) {
      return timezone._instance;
    }
    timezone._instance = this;
  }
  updateTimezone = (newOffset = this.getLocalTimezone()) => {
    this.offset = newOffset;
    this.setOffset(newOffset);
    return this.offset;
  };
  setToLocal = () => {
    this.setOffset(this.getLocalTimezone());
  };
  setOffset = newOffset => {
    localStorage.setItem('CustomTimeZoneOffset', newOffset);
    window.location.reload(true);
  };
  isEqualToLocal = () => {
    return this.getLocalTimezone() === this.offset;
  };
}

const TimezoneOffsetInstance = new timezone();

export { TimezoneOffsetInstance };
