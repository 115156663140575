import axios from 'axios';
export const addFCMToken = async FCMToken => {
  const res = await axios.post('/User/AddFCM', '"' + FCMToken + '"', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return Promise.resolve(res.data.Data);
};

export const removeFCMToken = async FCMToken => {
  if (!FCMToken) return Promise.resolve(true);
  const res = await axios.post('/User/RemoveFCM', '"' + FCMToken + '"', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return Promise.resolve(res.data.Data);
};
