import {
  FILTER,
  GET_PAGE,
  URL_SEARCH_PARAMS,
  INITIALIZE_STATE,
  RESET_STATE,
  CHANGE_HAPPENEND,
  GET_PAGE_NOTIFICATIONS
} from '../ReduxActions/types';
import { formatDateDisplay } from './paramsReducer';
import { subDays } from 'date-fns';
const UrlParamsJson = {
  StoreID:
    localStorage.SelectedStores &&
    JSON.parse(localStorage.getItem('SelectedStores')).globalStoreParam
      ? JSON.parse(localStorage.SelectedStores).globalStoreParam.Id
      : 0,
  sDate: localStorage.sdate
    ? localStorage.sdate
    : formatDateDisplay(subDays(new Date(), 14)),
  eDate: localStorage.edate
    ? localStorage.edate
    : formatDateDisplay(new Date()),
  filterType: localStorage.filterType ? localStorage.filterType : '1'
};
var initialState = {
  filter: '',
  pageName: null,
  changeHappenend: false,
  urlParamsSearch: UrlParamsJson,
  urlParamsSearchString:
    '?' +
    Object.keys(UrlParamsJson)
      .map(param => {
        return param + '=' + UrlParamsJson[param];
      })
      .join('&'),
  notification: null
};

export default function (state = null, action) {
  let nextState = { ...state };
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case FILTER:
      nextState = {
        ...state,
        filter: action.payload
      };
      break;
    case GET_PAGE:
      nextState = {
        ...state,
        pageName: action.payload
      };
      break;
    case URL_SEARCH_PARAMS:
      const newurlParamsSearch = {
        ...state.urlParamsSearch,
        ...action.payload
      };
      nextState = {
        ...state,
        urlParamsSearch: newurlParamsSearch,
        urlParamsSearchString:
          '?' +
          Object.keys(newurlParamsSearch)
            .map(param => {
              return param + '=' + newurlParamsSearch[param];
            })
            .join('&')
      };
      break;
    case CHANGE_HAPPENEND:
      nextState = {
        ...state,
        changeHappenend: action.payload
      };

      break;
    case GET_PAGE_NOTIFICATIONS:
      nextState = {
        ...state,
        notification: action.payload
      };
      break;
    default:
      break;
  }
  return nextState;
}
