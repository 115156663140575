import React from 'react';
import useRequest from '../../Hooks/useRequest';
import { Unsubscribe as UnsubscribeAPI } from '../../Helper/api';
import { useLocation } from 'react-router-dom';
import ContentOverlayLoader from '../../Components/ContentOverlayLoader';
import Status from '../../Constants/Status';
import { useIntl } from 'react-intl';
const Unsubscribe = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const { data, status, ErrorMessage } = useRequest(
    () => UnsubscribeAPI(search),
    []
  );
  return (
    <div className="w-100">
      <ContentOverlayLoader
        status={status}
        error={formatMessage({ id: 'Unsubscribe.error' })}
      >
        {status === Status.Fullfilled ? (
          <p>{formatMessage({ id: 'Unsubscribe.success' })}</p>
        ) : (
          <></>
        )}
      </ContentOverlayLoader>
    </div>
  );
};

export default Unsubscribe;
