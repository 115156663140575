import {
  FILTER,
  GET_PAGE,
  CHANGE_HAPPENEND,
  GET_PAGE_NOTIFICATIONS
} from './types';

export function filterUpdate(filter) {
  return dispatch => {
    dispatch({
      type: FILTER,
      payload: filter
    });
  };
}

export function getPage(p) {
  return dispatch => {
    dispatch({
      type: GET_PAGE,
      payload: p
    });
  };
}

export function onChangeHappenend(changeHappenend) {
  return dispatch => {
    dispatch({
      type: CHANGE_HAPPENEND,
      payload: changeHappenend
    });
  };
}
export function getPageNotification(notification) {
  return dispatch => {
    dispatch({
      type: GET_PAGE_NOTIFICATIONS,
      payload: notification
    });
  };
}
