import React, { Component } from 'react';
import Logo from '../Components/Logo';
import { connect } from 'react-redux';
import { getPath } from '../ReduxActions/userActions';
import axios from 'axios';
import { NavLink, Route } from 'react-router-dom';
import { axiosBuilder } from '@udentify/uxios';
export default function LoginLayout(Comp, _setlang) {
  class LoginLayoutComp extends Component {
    componentDidMount() {
      document.title = 'Udentify';
      // var path = window.location.href
      //   .split('redirect=')
      //   .join('')
      //   .split('Login?')
      //   .join('');
      // var pathStore = window.location.search.split('&')[0].split('=')[2];
      // this.props.getPath({ path: path, pathStore: pathStore });
    }
    setLang(lang) {
      _setlang(lang);
    }
    render() {
      return (
        <div className="Login__Wrapper">
          <div className="Logo__Login">
            {this.props.location.pathname === '/ForgetPassword' ||
            this.props.location.pathname === '/Register' ? (
              <div
                className="icon goBack"
                onClick={this.props.history.goBack}
              ></div>
            ) : (
              <NavLink to={'/'}>
                <Logo />
              </NavLink>
            )}
          </div>
          <div className="Login__Layout">
            <div className="LoginForm__Wrapper">
              <div
                style={{
                  position: 'absolute',
                  top: '-80px',
                  color: 'var(--danger)'
                }}
              >
                {this.props.error}
              </div>
              <Comp
                pending={this.props.pending}
                success={this.props.success}
                error={this.props.error}
                isAuth={
                  axiosBuilder.getInstance().defaults.headers.common[
                    'Authorization'
                  ]
                }
              />
            </div>
          </div>
          <div className="Login__RightsideBg">
            {/* <img src={process.env.PUBLIC_URL + '/Login_Bg.png'} /> */}
          </div>
          <div className="Select__Lang__Buttons">
            <button
              className="uButton link default"
              onClick={this.setLang.bind(this, 'en')}
            >
              <i className="icon flag-en" />
              English
            </button>
            <button
              className="uButton link default"
              onClick={this.setLang.bind(this, 'tr')}
            >
              <i className="icon flag-tr" />
              Türkçe
            </button>
            <button
              className="uButton link default"
              onClick={this.setLang.bind(this, 'de')}
            >
              <i className="icon flag-de " />
              Deutsche
            </button>
          </div>
        </div>
      );
    }
  }
  const mapStateTopProps = state => ({
    error: state.user.error,
    pending: state.user.pending,
    success: state.user.success
  });

  const mapDispatchToProps = dispatchEvent => ({
    getPath: path => dispatchEvent(getPath(path))
  });

  return connect(mapStateTopProps, mapDispatchToProps)(LoginLayoutComp);
}
